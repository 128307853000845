<template>
  <div>

        <Pane/>

    <a-card class="container">
      <a-form-model layout="inline" :colon="false" :model="form" @keyup.enter.native="query">
            <a-form-model-item>
              <a-month-picker
                valueFormat="YYYY-MM"
                style="width: 140px"
                placeholder="计划月份"
                v-model="form.yearMonth"
              />
            </a-form-model-item>
            <a-form-model-item>
              <a-select
                style="width: 140px"
                placeholder="请选择部门"
                v-model="form.deptId"
              >
                <a-select-option
                  v-for="item in organizationList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.uniqueName }}</a-select-option
                >
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-select placeholder="处理状态" v-model="form.confirmStatus" style="width: 140px">
                <a-select-option v-for="item in confirmStatusList" :key="item.value" :value="item.value">{{ item.name
                                  }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <a-select placeholder="完成状态" v-model="form.completionStatus" style="width: 140px">
                <a-select-option v-for="item in completionStatusList" :key="item.value" :value="item.value">{{ item.name
                                  }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
                    total,
                    current,
                    pageSize,
                    showTotal: (total) => `共 ${total} 条记录`,
                  }"
        rowKey="id"
      >


        <a-table-column title="部门" data-index='deptName' align='center' />
        <a-table-column title="计划月份">
          <template slot-scope="text">
            {{text.year + "年" + text.month + "月"}}
          </template>
        </a-table-column>
        <a-table-column title="填报时间" data-index='createAt' />          
        <a-table-column title="处理状态" align='right'>
          <template slot-scope="text">
            <DataDictFinder
              dictType="project_plan_confirm_status"
              :dictValue="String(text.confirmStatus)"
            />
          </template>
        </a-table-column>
        <a-table-column title="完成状态" align='right'>
          <template slot-scope="text">
            <DataDictFinder
              dictType="project_plan_completion_status"
              :dictValue="String(text.completionStatus)"
            />
          </template>
        </a-table-column>
        <a-table-column key="control" title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a @click="showDetail(text.id)">详情</a>
              <a @click="confirm(text)">确认</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import request from "@/api/request";
import moment from 'moment'
import { mapGetters } from "vuex";
import organization from "@/mixins/organization";


function fetchList(params) {
  return request({
    url: '/market-service/epc/view/progress/plan/list',
    params,
  });
}
function fetchDetail(params) {
  return request({
    url: '/market-service/epc/view/progress/plan/detail/' + params,
  });
}
export default {
  mixins: [ organization, watermark],

  components: {
    // MonthList,
    // YearList,
    // HistoryList,
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    confirmStatusList() {
      return this.findDataDict("project_plan_confirm_status");
    },
    completionStatusList() {
      return this.findDataDict("project_plan_completion_status");
    },
  },
  data() {
    return {
      historyAnnualData: [],
      historyProfit: 0,
      statistic: [],
      statisticProfit: 0,
      month: moment().format("YYYY-MM"),

      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  mounted() {
    const now = new Date();
    this.form.yearMonth = now.getFullYear() + "-" + (now.getMonth() + 1);
    this.getList();
  },

  methods: {
    showDetail(id) {
      fetchDetail(id)
        .then(res => {
          if (Array.isArray(res.list)) {
            console.log(res.list)
          }
        })
        .finally(() => {
        });
    },
    confirm(text) {
      console.log(text)
    },
    getList() {
      const { current, pageSize } = this;
      this.loading = true;

      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize ? res.totalSize : 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },


    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

  }
};
</script>
<style lang="less" scoped>
.card {
  display: flex;

  .item {
    border: 2px solid #f0f0f0;
    margin-right: 12px;
    padding: 12px;
    border-radius: 2px;
    min-width: 240px;
    position: relative;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .extra {
        margin-left: auto;
        cursor: pointer;
      }
    }

    .value {
      font-size: 2em;
      color: #1890ff;
    }

    .setting {
      position: absolute;
      bottom: 12px;
      right: 12px;
      cursor: pointer;
    }
  }

  .active {
    border-color: #1890ff;
  }
}
</style>